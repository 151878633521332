<template>
  <section id="categoryCharacter">
    <b-row>
      <b-col>
        <h1>{{ $t('catAvatar') }}</h1>
        <h5 class="text-primary">
          {{ $t('platfConf') }}
        </h5>
      </b-col>
    </b-row>
    <b-overlay
      :show="show"
      variant="transparent"
      :opacity="1.00"
      :blur="'2px'"
      rounded="lg"
    >
      <b-row class="mt-2">
        <!-- RUTA DE NAVEGACIÓN POR CATEGORÍAS -->
        <b-col md="11">
          <b-breadcrumb>
            <b-breadcrumb-item
              v-for="(brc, index) in navItems"
              :key="brc.text + '_' + brc.id"
              :text="brc.text"
              :active="index === navItems.length - 1 ? true : false"
              :to="{ name: 'avatar-category' }"
            />
          </b-breadcrumb>
        </b-col>
        <b-col
          md="1"
          class="d-flex justify-content-end align-items-center"
        >
          <!-- BUTTON VOLVER -->
          <div v-if="navItems.length > 1">
            <div>
              <b-button
                variant="danger"
                :to="{ name: 'avatar-category' }"
              >
                {{ $t("dataGeneric.goBack") }}
              </b-button>
            </div>
          </div>
        </b-col>
      </b-row>
      <!-- APARTADO ORDENACIÓN MANUAL -->
      <b-row class="mt-2 justify-content-end">
        <div
          v-if="!isCharacter"
          class="d-flex  align-items-center"
        >
          <b-col
            v-if="manual"
            class="d-flex"
          >
            <b-button
              variant="outline-warning"
              disabled
            >
              {{ $t("contents.manualSortMode") }}
            </b-button>

            <b-button
              class="ml-2"
              variant="success"
              @click.stop.prevent="manual = !manual"
            >
              {{ $t("contents.finalize") }}
            </b-button>
          </b-col>
          <b-col v-else>
            <b-button
              class="ml-2"
              @click.stop.prevent="manual = !manual"
            >
              {{ $t('contents.sort') }}
            </b-button>
          </b-col>
          <b-col>
            <b-button
              variant="primary"
              class="d-flex"
              @click="actionsCRUD('create')"
            >
              <feather-icon
                icon="FolderPlusIcon"
                class="mr-50"
              />
              <span class="text-nowrap">{{ $t("contents.addCategory") }}</span>
            </b-button>
          </b-col>
        </div>
        <div
          v-else
          class="d-flex  align-items-center"
        >
          <b-col
            v-if="manual"
            class="d-flex"
          >
            <b-button
              variant="outline-warning"
              disabled
            >
              {{ $t("contents.manualSortMode") }}
            </b-button>
            <b-button
              class="ml-2"
              variant="success"
              @click.stop.prevent="manual = !manual"
            >
              {{ $t("contents.finalize") }}
            </b-button>
          </b-col>
          <b-col v-else>
            <b-button
              class="ml-2"
              @click.stop.prevent="manual = !manual"
            >
              {{ $t('contents.sort') }}
            </b-button>
          </b-col>
          <b-col>
            <b-button
              variant="primary"
              class="d-flex"
              @click="$refs['avatar'].show()"
            >
              <feather-icon
                icon="UsersIcon"
                class="mr-50"
              />
              <span class="text-nowrap">{{ $t('addAvatar') }}</span>
            </b-button>
          </b-col>
        </div>
      </b-row>
      <!-- NÚMERO DE ELEMENTOS -->
      <b-row>
        <b-col class="d-flex justify-content-end mt-2">
          <span>
            <strong>{{ isCharacter ? totalCharacter : totalCategories }}</strong> {{ $t("contents.elements") }}
          </span>
        </b-col>
      </b-row>
      <b-row
        v-if="!isCharacter"
        class="match-height mt-2"
      >
        <b-col>
          <draggable
            v-if="manual"
            :list="categoryCharacters"
            class="row manualOrder rounded"
            group="categories"
            :sort="true"
          >
            <b-col
              v-for="folder in categoryCharacters"
              :id="folder.node.id + ' ' + folder.node.order"
              :key="folder.node.id"
              style="border-radius: 7px;"
              class="m-2 embed-responsive embed-responsive-16by9 hang-on-hover"
              md="2"
              @dragend="updateOrder($event)"
            >
              <b-card
                header-classes="bg-transparent"
                footer-classes="bg-transparent"
                body-classes="px-lg-7"
                class=" embed-responsive-item text-center mb-2 custom-height cursor-pointer"
                no-body
              >
                <b-card-body>
                  <div
                    id="acortarPadre"
                    class="m-2 mt-5"
                  >
                    <div class="wrap">
                      <h2 class="text-white acortarParrafo">
                        {{ folder.node.name }}
                      </h2>
                      <div class="acortarParrafo">
                        {{ folder.node.description }}
                      </div>
                    </div>
                  </div>
                </b-card-body>
              </b-card>
            </b-col>
          </draggable>
          <b-row v-else>
            <b-col
              v-for="folder in categoryCharacters"
              :id="folder.node.id + ' ' + folder.node.order"
              :key="folder.node.id"
              style="border-radius: 7px;"
              class="m-2 embed-responsive embed-responsive-16by9"
              md="3"
            >
              <b-card
                header-classes="bg-transparent"
                footer-classes="bg-transparent"
                body-classes="px-lg-7"
                class=" embed-responsive-item text-center mb-2 custom-height cursor-pointer"
                no-body
                @click.stop.prevent="$router.push({ name: 'avatar-subCategory', params: { id: folder.node.id } })"
              >
                <b-card-body>
                  <div class="actions">
                    <div class="action">
                      <b-dropdown
                        variant="link"
                        toggle-class="text-decoration-none"
                        no-caret
                        right
                      >
                        <template #button-content>
                          <feather-icon
                            icon="MoreHorizontalIcon"
                            size="20"
                          />
                        </template>
                        <b-dropdown-item
                          @click.stop.prevent="actionsCRUD(
                            'update',
                            folder.node
                          )"
                        >
                          <feather-icon
                            icon="Edit2Icon"
                            size="18"
                          />
                          {{ $t('dataGeneric.edit') }}
                        </b-dropdown-item>
                        <b-dropdown-item @click.stop.prevent="actionsCRUD('delete', folder.node)">
                          <feather-icon
                            icon="DeleteIcon"
                            size="18"
                          />
                          {{ $t('dataGeneric.delete') }}
                        </b-dropdown-item>
                      </b-dropdown>
                    </div>
                  </div>
                  <div
                    id="acortarPadre"
                    class="m-2 mt-5"
                  >
                    <div
                      class="wrap"
                      style="height: 140px;"
                    >
                      <h2 class="display-5 acortarParrafo">
                        {{ folder.node.name }}
                      </h2>
                      <div class="acortarParrafo">
                        {{ folder.node.description }}
                      </div>
                    </div>
                  </div>
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-card
        v-if="totalCharacter > 0"
        no-body
        class="m-1"
      >
        <draggable
          v-if="manual"
          :list="characters"
          class="row manualOrder rounded"
          group="categories"
          :sort="true"
        >
          <b-col
            v-for="i in characters"
            :id="i.node.id + ' ' + i.node.order"
            :key="i.node.character.id"
            md="1"
            class="m-1 cursor-move hang-on-hover"
            @dragend="updateOrderCharacters($event)"
          >
            <div class="profile-pic">
              <b-avatar size="8em">
                <b-img
                  :src="i.node.character.image"
                  class="image_avatar"
                  rounded="circle"
                />
              </b-avatar>
            </div>
            <div
              class="mt-1"
              style="color:black; text-align-last: center;"
            >
              <p>{{ i.node.character.name }}</p>
            </div>
          </b-col>
        </draggable>
        <b-row
          v-else
          class="m-2"
        >
          <b-col
            v-for="i in characters "
            :key="i.node.character.id"
            md="1"
            class="m-1"
          >
            <div class="profile-pic">
              <b-avatar size="8em">
                <b-img
                  :src="i.node.character.image"
                  class="image_avatar"
                  rounded="circle"
                />
              </b-avatar>
              <div
                v-b-tooltip.hover.top.v-primary="$t('clickH')"
                class="editImage"
                style="top: -1em; left: 6em;"
                @click="removeAvatar(i.node.id)"
              >
                <feather-icon
                  class="del"
                  icon="XIcon"
                  size="18"
                />
              </div>
            </div>
            <div
              class="mt-1"
              style="text-align-last: center;"
            >
              <p>{{ i.node.character.name }}</p>
            </div>
          </b-col>
        </b-row>
      </b-card>
    </b-overlay>
    <b-modal
      id="modal-prevent-closing"
      ref="modal"
      centered
      size="lg"
      title="Categoría"
      @hidden="resetModal"
      @ok="validationForm"
    >
      <validation-observer
        ref="categories"
        tag="form"
        @submit.stop.prevent="validationForm"
      >
        <b-overlay
          :show="show"
          :opacity="1.00"
          :blur="'2px'"
          rounded="lg"
        >
          <validation-provider
            v-slot="{ errors }"
            name="name-input"
            rules="required"
          >
            <b-form-group
              :label="$t('firstName') + '*'"
              label-for="name-input"
              :invalid-feedback="$t('required')"
              :state="errors.length > 0 ? false : null"
            >
              <b-form-input
                id="name-input"
                v-model="name"
                :placeholder="$t('firstName')"
                :state="errors.length > 0 ? false : null"
                maxlength="150"
                required
              />
            </b-form-group>
          </validation-provider>
          <b-form-group
            :label="$t('dataGeneric.description')"
            label-for="description"
          >
            <b-form-textarea
              id="description"
              v-model="description"
              class="mb-1"
              :placeholder="$t('dataGeneric.description')"
              rows="2"
              max-rows="4"
            />
          </b-form-group>
        </b-overlay>
      </validation-observer>
      <template #modal-footer="{ ok, cancel }">
        <b-overlay
          :show="show"
          variant="transparent"
          class="d-flex"
          :opacity="1.00"
          :blur="'2px'"
          rounded="lg"
        >
          <template #overlay>
            <div class="d-flex align-items-center">
              <b-spinner
                small
                type="grow"
                variant="secondary"
              />
              <b-spinner
                type="grow"
                variant="dark"
              />
              <b-spinner
                small
                type="grow"
                variant="secondary"
              />
              <span class="sr-only">{{ $t('wait') }}...</span>
            </div>
          </template>
          <b-button
            variant="danger"
            @click="cancel()"
          >
            {{ $t('dataGeneric.cancel') }}
          </b-button>
          <b-button
            variant="primary"
            class="ml-1"
            @click="ok()"
          >
            {{ $t('dataGeneric.save') }}
          </b-button>
        </b-overlay>
      </template>
    </b-modal>
    <!-- MODAL PARA SELECCIONAR CATEGORÍA -->
    <b-modal
      ref="avatar"
      size="lg"
      hide-footer
      :title="$t('slider.selectionMultiple')"
    >
      <div class="d-block text-center">
        <avatar-selector
          :old="characters"
          @confirm="confirmData"
        />
      </div>
      <b-button
        class="mt-3"
        variant="outline-danger"
        block
        @click.stop.prevent="$refs['avatar'].hide()"
      >
        {{ $t("dataGeneric.close") }}
      </b-button>
    </b-modal>
  </section>
</template>
<script>
import draggable from 'vuedraggable'

import {
  BButton,
  BRow,
  BCol,
  BCard,
  BCardBody,
  BBreadcrumb, BBreadcrumbItem,
  BImg,
  BFormInput,
  BOverlay,
  BDropdown,
  BDropdownItem,
  BAvatar,
  VBTooltip,
  BSpinner,
  BFormTextarea,
  BFormGroup,
} from 'bootstrap-vue'
import {
  showToast, messageError, utf8ToB64, base64Encode,
} from '@/store/functions'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'
import { getUserData } from '@/auth/utils'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import AvatarSelector from '../../common/AvatarSelector.vue'

const fileError = require('@/assets/images/backend/file-error.png')

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormTextarea,
    BCard,
    AvatarSelector,
    BDropdown,
    BBreadcrumb,
    BBreadcrumbItem,
    BDropdownItem,
    ValidationProvider,
    ValidationObserver,
    BCardBody,
    BAvatar,
    BSpinner,
    BImg,
    BFormGroup,
    draggable,
    BFormInput,
    BOverlay,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      required,
      show: false,
      id: null,
      name: '',
      description: '',
      imageShow: '',
      headers: {},
      imageSrc: null,
      manual: false,
      imageBol: false,
      imageFile: null,
      contentsTags: [],
      contents: [],
      categories: [],
      navItems: [{
        id: null,
        text: this.$t('contents.start'),
      }],
      categoriesTags: [],
      nameState: null,
      selected: [],
      idTags: [],
      originalTags: [],
      imageState: null,
      isCharacter: false,
      totalCharacter: 0,
      totalCategories: 0,
      categoryCharacters: [],
      characters: [],
      code: this.$route.params.id != null ? this.$route.params.id : null,
    }
  },
  watch: {
    contentsTags() {
      this.contents = this.contents.filter(item => this.contentsTags.includes(item.tag))
    },
    categoriesTags() {
      this.categories = this.categories.filter(item => this.categoriesTags.includes(item.tag))
    },
    imageFile(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          base64Encode(newValue)
            .then(value => {
              this.imageBol = true
              this.imageSrc = value
            })
            .catch(() => {
              this.imageSrc = null
            })
        } else {
          this.imageSrc = null
        }
      }
    },
  },
  mounted() {
    let defaultLang = 'es'
    try {
      defaultLang = getUserData.profile.client.defaultLanguage
      // eslint-disable-next-line no-empty
    } catch (error) { }

    this.headers = {
      'Accept-Language': defaultLang,
      'Content-Language': defaultLang,
    }

    this.getCategoryCharacter(this.code)
  },
  methods: {
    removeAvatar(id) {
      const query = `mutation{
          deleteCharacterCategoryOrder(id:"${id}"){
            found
            deletedId
          }
        }`

      axios
        .post('', { query })
        .then(result => {
          messageError(result, this)
          this.$refs.avatar.hide()
          showToast(this.$t('success'), 1, this)
          this.getCategoryCharacter(this.code)
        })
        .catch(err => {
          showToast(this.$t('error'), 2, this)
          console.log(err)
        })
    },
    getCategory(item) {
      const { id, name } = item.node
      this.categoriesTags.push(name)
      this.categories.push({ id, tag: name })
      this.hideModal('cat')
    },
    getContent(item) {
      const id = item[0]
      const tag = item[1]
      this.contentsTags.push(tag)
      this.contents.push({ id, tag })
      this.hideModal('cont')
    },
    showModal(type) {
      switch (type) {
        case 'cat':
          this.$refs.CatModal.show()
          break
        case 'cont':
          this.$refs.ConModal.show()
          break
        default:

          break
      }
    },
    hideModal(type) {
      switch (type) {
        case 'cat':
          this.$refs.CatModal.hide()
          break
        case 'cont':
          this.$refs.ConModal.hide()
          break
        default:
          break
      }
    },
    getCategoryCharacter(id = null) {
      const { headers } = this
      const userData = getUserData()
      this.isCharacter = !!id
      let query = ''
      if (this.isCharacter) {
        const categoryCode = utf8ToB64(`id:${id}`)
        query = `{
          categoryCharacter(              
              id:"${id}",
              ){              
              edges {
                node {
                  id
                  name                                                
                }
              }
            }
          
        categoryCharacterOrder(category:"${categoryCode}",orderBy:"order") {
          totalCount
              edges {
                node {
                  id
                  category {
                    id
                    name
                  }
                  character {
                    id
                    name
                    description
                    image      
                  }
                  order
                }
              }
            }
          }`
      } else {
        query = `{
            categoryCharacter(              
              client:"${userData.profile.client.id}",
              orderBy:"order"){
              totalCount
              edges {
                node {
                  id
                  name
                  description
                  order                                      
                }
              }
            }
          }`
      }

      axios
        .post('', {
          query,
        }, { headers })
        .then(result => {
          messageError(result, this)
          if (this.isCharacter) {
            const relation = result.data.data.categoryCharacterOrder.edges
            const category = result.data.data.categoryCharacter.edges[0].node
            if (this.navItems.length === 1) {
              this.navItems.push({
                text: category.name,
                id: category.id,
              })
            }
            this.characters = relation
            this.totalCharacter = result.data.data.categoryCharacterOrder.totalCount
          } else {
            this.totalCategories = result.data.data.categoryCharacter.totalCount
            this.categoryCharacters = result.data.data.categoryCharacter.edges
          }
          this.show = false
        })
        .catch(err => {
          console.log(err)
        })
    },
    confirmData(data) {
      let order = this.totalCharacter
      let query = 'mutation{'
      data.data.forEach((i, n) => {
        query += `create${n + 1}: createCharacterCategoryOrder(input:{category:"${this.code}",character:"${i}",order:${order}}){
            characterCategoryCharacter{
              id
            }
          }`
        order += 1
      })
      query += '}'

      axios
        .post('', { query })
        .then(result => {
          messageError(result, this)
          this.$refs.avatar.hide()
          showToast(this.$t('success'), 1, this)
          this.getCategoryCharacter(this.code)
        })
        .catch(err => {
          showToast(this.$t('error'), 2, this)
          console.log(err)
        })
    },
    handleOk() {
      if (this.id != null) { this.updateCharacterCategory() } else this.createCharacterCategory()
    },

    createCharacterCategory() {
      this.show = true
      const userData = getUserData()
      const data = new FormData()
      const config = {
        timeout: 1000 * 60 * 30,
      }
      const { headers } = this

      const query = `
            mutation($name: String!,$description:String,$client:ID!){
              createCharacterCategory(input:{
                name:$name,
                description:$description,
                client:$client,
                order:${this.totalCategories},               
                }){
                categoryCharacter {
                  id
                  name
                  
                }
              }
            }`
      const variables = {
        name: this.name,
        description: this.description,
        client: userData.profile.client.id,
      }
      data.append('query', query)
      data.append('variables', JSON.stringify(variables))
      data.append('image', this.imageFile)
      axios
        .post('', data, { headers }, config)
        .then(result => {
          messageError(result, this)
          this.$nextTick(() => {
            this.$bvModal.hide('modal-prevent-closing')
          })
          showToast(this.$t('code.updateData'), 1, this)

          this.getCategoryCharacter(this.code)
        })
        .catch(err => {
          showToast(this.$t('code.updateDataError'), 2, this)
          console.log(err)
        })
    },
    validationForm(bvModalEvt) {
      bvModalEvt.preventDefault()
      return new Promise(resolve => {
        this.$refs.categories.validate().then(success => {
          if (success) {
            resolve(true)
            this.handleOk()
          } else {
            /* this.disabled = false;
            reject(); */
          }
        })
      })
    },
    formatObject(object) {
      const array = []

      object.forEach(element => {
        array.push(element.id)
      })
      return array
    },
    updateCharacterCategory() {
      const { headers } = this

      this.show = true
      const data = new FormData()
      const config = {
        timeout: 1000 * 60 * 30,
      }
      const query = `
            mutation($name: String!,$description:String){
              updateCharacterCategory(id:"${this.id}",input:{
                name:$name,
                description:$description,                
                }){
                categoryCharacter {
                  id
                  name                  
                }
              }
            }`
      const variables = {
        name: this.name,
        description: this.description,
      }
      data.append('query', query)
      data.append('variables', JSON.stringify(variables))

      axios
        .post('', data, { headers }, config)
        .then(result => {
          messageError(result, this)
          this.$nextTick(() => {
            this.$bvModal.hide('modal-prevent-closing')
          })
          showToast(this.$t('code.updateData'), 1, this)
          this.getCategoryCharacter(this.code)
        })
        .catch(err => {
          showToast(this.$t('code.updateDataError'), 2, this)
          console.log(err)
        })
    },
    errorImg(e) {
      e.target.src = fileError
    },
    updateOrder(event) {
      this.show = true
      const arrayNum = []
      const arrayId = []
      for (let i = 0; i < event.target.parentNode.childNodes.length; i += 1) {
        if (event.target.parentNode.childNodes[i]) {
          arrayId.push(event.target.parentNode.childNodes[i].id.split(' ')[0])
          arrayNum.push(event.target.parentNode.childNodes[i].id.split(' ')[1])
        }
      }
      arrayNum.sort((a, b) => a - b)

      const dataArr = new Set(arrayNum)

      const result = [...dataArr]
      while (result.length !== arrayNum.length) {
        const n = parseInt(result[result.length - 1], 10)
        result.push((n + 1).toString())
      }

      let query = ` mutation
      {`

      for (let i = 0; i < result.length; i += 1) {
        query += `
        m${i + 1}: updateCharacterCategory(id:"${arrayId[i]}",input:{ order: ${result[i]
}}){
            categoryCharacter{
                      id
                      name
                      order
                    }
                  }
                `
      }
      query += `
      }`

      axios
        .post('', {
          query,
        })
        .then(res => {
          messageError(res, this)

          showToast(this.$t('success'), 1, this)
          this.show = false
        })
        .catch(error => {
          console.log(error)
          showToast(this.$t('error'), 0, this)
          this.show = false
        })
    },
    updateOrderCharacters(event) {
      this.show = true
      const arrayNum = []
      const arrayId = []
      for (let i = 0; i < event.target.parentNode.childNodes.length; i += 1) {
        if (event.target.parentNode.childNodes[i]) {
          arrayId.push(event.target.parentNode.childNodes[i].id.split(' ')[0])
          arrayNum.push(event.target.parentNode.childNodes[i].id.split(' ')[1])
        }
      }

      const newOrder = ['0']
      while (newOrder.length !== arrayNum.length) {
        const n = parseInt(newOrder[newOrder.length - 1], 10)
        newOrder.push((n + 1).toString())
      }

      let query = ` mutation
      {`
      for (let i = 0; i < newOrder.length; i += 1) {
        query += `
        m${i + 1}: updateCharacterCategoryOrder(id:"${arrayId[i]}",input:{ 
          order: ${newOrder[i]}
        }){
          characterCategoryCharacter{
                      id                      
                      order
                    }
                  }
                `
      }
      query += `
      }`
      axios
        .post('', {
          query,
        })
        .then(res => {
          messageError(res, this)

          showToast(this.$t('success'), 1, this)
          this.show = false
        })
        .catch(error => {
          console.log(error)
          showToast(this.$t('error'), 0, this)
          this.show = false
        })
    },
    actionsCRUD(value, data = []) {
      const { id, name, description } = data
      switch (value) {
        case 'update':
          this.id = id
          this.name = name
          this.description = description
          this.$refs.modal.show()

          break
        case 'create':
          this.id = null
          this.name = null
          this.description = null
          this.$refs.modal.show()
          break
        case 'delete':
          this.show = true
          this.$swal({
            title: this.$t('code.title'),
            text: this.$t('remCatAv'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: this.$t('code.confirm'),
            cancelButtonText: this.$t('dataGeneric.cancel'),
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
          }).then(result => {
            if (result.value) {
              axios
                .post('', {
                  query: `
                  mutation{
                    deleteCharacterCategory(id:"${id}"){
                          found
                          deletedId
                      }
                  }
                  `,
                })
                .then(res => {
                  messageError(res, this)
                  showToast(this.$t('code.updateData'), 1, this)
                  this.getCategoryCharacter(this.code)
                })
                .catch(() => {
                  showToast(this.$t('code.updateDataError'), 2, this)
                  this.show = false
                })
            } else {
              this.show = false
            }
          }).catch(err => {
            console.log(err)
          })
          break

        default:
          break
      }
    },
    chooseInput(dato) {
      document.getElementById(dato).click()
    },
    resetModal() {
      this.name = ''
      this.id = null
      this.description = ''
      this.nameState = null
      this.imageState = null
      this.show = false
    },

  },
}
</script>
<style lang="scss" scoped>
.card-body {
  padding: 1rem;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  min-height: 1px;
}

.card-body h4 {
  font-size: 1.286rem !important;
}

.col-form-label {
  font-size: 20px;
  font-weight: 500;
  text-align: center;
}

.fly-image-input {
  max-width: 100%;
}

.base-color-input {
  display: block;
  border-radius: 15px;
  border: solid 1px;
  border-color: white;
  cursor: pointer;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.base-card-input {
  display: block;
  cursor: pointer;
  background-size: cover;
  background-position: center center;
}

.div {
  display: inline-block;
}

.inputColor {
  visibility: hidden;
}

.placeholder_photo {
  background: #f0f0f0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #333;
  font-size: 18px;
  font-family: Helvetica;
}

.placeholder_photo:hover {
  background: #e0e0e0;
}

.file-input {
  display: none;
}

.cBox {
  align-items: center;
}

.custom-control-label {
  font-size: 15px;
}

.scroll {
  overflow: hidden;
  flex-wrap: nowrap !important;

}

.list {
  overflow: hidden;
  overflow-y: scroll;
  height: 380px;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  display: none;
}

.contain {
  object-fit: contain;
  cursor: pointer;
}

.image_avatar {
  max-width: initial;
  max-height: max-content;
  height: 100%;
  width: 100%;
}

.editImage {
  position: absolute;
}

.profile-pic:hover .editImage {
  display: block;
}

.profile-pic {
  position: relative;
  display: inline-block;
}

#categoryCharacter .actions {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: rgb(0 15 51 / 73%);
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}

#categoryCharacter .card-body {
  padding: 0rem;
}

#categoryCharacter .card-body h4 {
  font-size: 1rem !important;
}

#categoryCharacter .card-body .actions {
  display: -webkit-flex;
  display: flex;
  transition: all 0.2s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 2.75rem;
  padding-right: 0.34375rem;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}

#categoryCharacter #button-content {
  padding: 0px;
}

#categoryCharacter #acortar {
  overflow: hidden;
  text-overflow: ellipsis;
}

.custom-height {
  height: 290px;
}

#categoryCharacter #acortarPadre {
  overflow: hidden;
  white-space: nowrap;
}

#categoryCharacter .acortarParrafo {
  display: block;
  display: -webkit-box;
  line-height: 1.4;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

#categoryCharacter .wrap {
  white-space: pre-wrap;
}

.del:hover {
  color: #a60303;
  cursor: pointer;
}

#categoryCharacter .breadcrumb {
  justify-content: flex-start;
  font-weight: bold;
}

.manualOrder {
  border: 5px dashed #e69d5d;
  background: repeating-linear-gradient(-55deg,
      #283046,
      #283046 10px,
      #161d31 10px,
      #161d31 20px);
}

@-webkit-keyframes hang-on-hover {
  0% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }

  50% {
    -webkit-transform: translateY(4px);
    transform: translateY(4px);
  }

  100% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
}

@keyframes hang-on-hover {
  0% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }

  50% {
    -webkit-transform: translateY(4px);
    transform: translateY(4px);
  }

  100% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
}

@-webkit-keyframes hang-on-hover-sink {
  100% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
}

@keyframes hang-on-hover-sink {
  100% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
}

.hang-on-hover {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}

.hang-on-hover:hover,
.hang-on-hover:focus,
.hang-on-hover:active {
  -webkit-animation-name: hang-on-hover-sink, hang-on-hover;
  animation-name: hang-on-hover-sink, hang-on-hover;
  -webkit-animation-duration: .3s, 1.5s;
  animation-duration: .3s, 1.5s;
  -webkit-animation-delay: 0s, .3s;
  animation-delay: 0s, .3s;
  -webkit-animation-timing-function: ease-out, ease-in-out;
  animation-timing-function: ease-out, ease-in-out;
  -webkit-animation-iteration-count: 1, infinite;
  animation-iteration-count: 1, infinite;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-direction: normal, alternate;
  animation-direction: normal, alternate;
}
</style>
